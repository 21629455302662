.main {
  width: 100%;
  height: 100%;
}


.navbar-brand,
.navbar-nav .nav-link {
  color: red;
  font-weight: bold;
  font-size: 22px;
  padding-left: 20px;
}



.navbar-brand:hover,
.navbar-nav .nav-link:hover {
  color: red;
}



.mr-auto {
  margin-left: 460px;

}

.navigation{
  background-color: #fae7bf;
  opacity: 0.8;
}

.header {
  text-align: center;

}

.header h1 {
  margin-top: 70px;
  font-size: 3rem;
  color: white;
}

.header h2 {
  font-size: 3rem;
  color: white;
  margin-top: 20px;
}



.header p {
  color: white;
  margin-top: 40px;
  font-size: 30px;

}


.heading {
  display: flex;
  margin-top: 40px;
}


.heading2 {
  margin-left: 30px;
}



.checks {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px 0;
}

.checks p {
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.checkout {
  flex: 1;
  margin: 10px;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}



.welcome {
  width: 90%;
  height: 600px;
  display: flex;
  margin-top: 20px;
  margin-left: 50px;
}


.welcome2 {
  width: 40%;
  height: 100%;
  
}

.welcome2 img {
  width: 100%;
  height: 100%;
 
}

.welcome3 {
  width: 50%;
  height: 550px;
  margin-top: 50px;
  margin-left: 60px;
}

.welcome3 h3 {
  margin-left: 70px;
  font-size: 20px;
  color: gray;
  margin-top: 10px;
}

.welcome3 h1 {
  margin-left: 70px;
  font-size: 35px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.welcome3 p {
  margin-left: 30px;
  margin-top: 25px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 17px;
}

.welcome3 button {
  margin-left: 30px;
}


.ourroom h1 {
  text-align: center;
  margin-top: 100px;
}


.rooms {
  width: 90%;
  height: 500px;
  margin-top: 30px;
  margin-left: 60px;
  display: flex;
}

.rooms2 {
  width: 31%;
  height: 100%;
  margin-left: 18px;
  background-color: #fae7bf;
}

.rooms2 img {
  width: 100%;
  height: 230px;
}

.rooms2 h2 {
  text-align: center;
  font-size: 17px;
  padding-top: 10px;
}

.rooms2 p {
 text-align: left;
  color: black;
 padding-left: 10px;
  font-size: 16px;
  padding-top: 10px;

}

.rooms2 h5 {
  text-align: center;
  font-size: 15px;
  padding-top: 20px;
}

.rooms2 span {
  color: gray;
  margin-left: 12px;
  font-size: 15px;
}



.slide {

  height: 390px;
  width: 87%;
  margin-left: 78px;
  margin-top: 20px;
  display: flex;
}

.slide2 {
  width: 24.5%;
  height: 100%;
  background-color: #fae7bf;
  margin-left: 04px;
}


.slide2 img {
  width: 100%;
  height: 200px;
}

.slide2 p {
  padding-top: 30px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 14px;
  padding-left: 10px;

}

.slide2 h1 {
  text-align: center;
  font-size: 25px;
  padding-top: 30px;
}

.maps{
  margin-top: 80px;
  height: 400px;
  border: 2px solid gray;
}


.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-container {
  margin: 10px;
}

.image-container img {
  width: 300px; /* Set your desired width */
  height: 200px; /* Set your desired height */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  border: 1px solid #ddd; /* Add a border for styling */
  border-radius: 8px; /* Optional: Add border-radius for rounded corners */
}

.contact {
  width: 400px;
  margin: 0 auto;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 8px;
}

input,
textarea {
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}


.footer {
  width: 100%;
  height: 400px;
  background-color: #232b2b;
  margin-top: 100px;
  display: flex;
}


.foot2 {
  width: 25%;
  height: 100%;

}

.foot2 h1 {
  color: white;
  font-size: 25px;
  text-align: center;
  padding-top: 30px;
}


.foot2 p {
  color: white;
  text-align: center;
  margin-top: 0px;
  font-size: 17px;
}

.diaclamer {
  width: 100%;
  height: 170px;
  background-color: #232b2b;
  display: flex;
}

.diaclamer h1 {
  padding-left: 0px;
  margin-left: 10px;
  color: white;
  border: 1px solid white;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 30px;
  padding-top: 10px;
}

.diaclamer p {
  color: white;
  margin-left: 300px;
  margin-top: 30px;
}

.videos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 20px;
  
  margin-top: 80px;
  background-color: #fae7bf;
}

video {
  width: 350px;
  height: 250px;
  margin: 10px;
}






@media (min-width: 300px) and (max-width: 600px) {

  .main {
    width: 100%;
    height: 100%;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding-left: 10px;

  }

  .navbar {
    position: fixed;
    top: 0;
    width: 100%;

    background-color: black;
  }

  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: #ffd700;
  }


  .mr-auto {
    margin-left: 10px;

  }



  .header {
    text-align: left;
    margin-top: -11px;
  }




  .header h1 {
    margin-top: 70px;
    font-size: 2.8rem;
    color: white;
    text-align: center;
    padding-top: 120px;
  }

  .header h2 {
    font-size: 2rem;
    color: white;
    margin-top: 20px;
    text-align: center;
  }

  .header p {
    color: white;
    margin-top: 40px;
    font-size: 30px;
    text-align: center;
  }




  .welcome {
    width: 100%;
    flex-direction: column;
    height: 100%;
    display: flex;
    margin-top: 20px;
    margin-left: 0px;
  }

  .welcome2 {
    width: 100%;
    height: 100%;
  }

  .welcome2 img {
    width: 100%;
    height: 100%;
  }

  .welcome3 {
    width: 100%;
    height: 100%;
    margin-top: 50px;
    margin-left: 0px;
  }

  .welcome3 h3 {
    margin-left: 30px;
    font-size: 20px;
    color: gray;
    margin-top: 10px;
  }

  .welcome3 h1 {
    margin-left: 30px;
    font-size: 35px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .welcome3 p {
    margin-left: 20px;
    margin-top: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
  }

  .welcome3 button {
    margin-left: 30px;
  }



  .ourroom {
    margin-top: 100px;
    height: auto;
  }

  .ourroom h1 {
    text-align: center;
    margin-top: 100px;
  }


  .rooms {
    width: 100%;
    height: auto;
    margin-top: 50px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
  }

  .rooms2 {
    width: 98%;
    height: 500px;
    margin-left: 03px;
    margin-top: 20px;
  }

  .rooms2 img {
    width: 100%;
    height: 230px;
  }

  .rooms2 h2 {
    text-align: center;
    font-size: 17px;
    padding-top: 15px;
  }

  .rooms2 p {
  text-align: left;
    color: black;
  padding-left: 10px;
    font-size: 18px;
    padding-top: 10px;
  }

  .rooms2 h5 {
    text-align: center;
    font-size: 15px;
    padding-top: 20px;
  }



  .slide {

    height: auto;
    width: 100%;
    margin-left: 0px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
  }

  .slide2 {
    width: 100%;
    height: 490px;
    background-color: #fae7bf;
    margin-left: 0px;
    margin-top: 50px;
  }


  .slide2 img {
    width: 100%;
    height: 250px;
  }

  .slide2 p {
    padding-top: 30px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 19px;
    padding-left: 10px;

  }

  .footer {
    width: 100%;
    height: 100%;
    background-color: #232b2b;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
  }


  .foot2 {
    width: 100%;
    height: 100%;

  }

  .foot2 h1 {
    color: white;
    font-size: 25px;
    text-align: center;
    padding-top: 30px;
  }


  .foot2 p {
    color: white;
    text-align: center;
    margin-top: 0px;
    font-size: 17px;
  }

  .diaclamer {
    width: 100%;
    height: 220px;
    background-color: #232b2b;
    display: flex;
    
  }

  .diaclamer h1 {
    padding-left: 0px;
    margin-left: 10px;
    color: white;
    border: 1px solid white;
    height: 60px;
    width: 100px;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    padding-top: 10px;
    margin-top: 50px;
  }

  .diaclamer p {
    color: white;
    margin-left: -200px;
    margin-top: 130px;
  }

}








@media (min-width: 600px) and (max-width: 1200px) {

  .main {
    width: 1200px;
    height: 100%;
  }

  .navbar-brand,
  .navbar-nav .nav-link {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding-left: 30px;

  }



  .navbar-brand:hover,
  .navbar-nav .nav-link:hover {
    color: #ffd700;
  }

  .mr-auto {
    margin-left: 700px;

  }

  .header {
    text-align: center;

  }

  .header h1 {
    margin-top: 70px;
    font-size: 6rem;
    color: white;
  }

  .header h2 {
    font-size: 3rem;
    color: white;
    margin-top: 20px;
  }

  .header p {
    color: white;
    margin-top: 40px;
    font-size: 30px;

  }


  .heading {
    display: flex;
    margin-top: 40px;
  }

  .heading2 {
    margin-left: 30px;
  }



  .checks {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px 0;
  }

  .checks p {
    color: black;
    font-weight: bold;
    font-size: 18px;
  }

  .checkout {
    flex: 1;
    margin: 10px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .date-picker {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }



  .welcome {
    width: 90%;

    height: 600px;
    display: flex;
    margin-top: 20px;
    margin-left: 50px;
  }

  .welcome2 {
    width: 40%;
    height: 100%;
  }

  .welcome2 img {
    width: 100%;
    height: 100%;
  }

  .welcome3 {
    width: 50%;
    height: 550px;
    margin-top: 50px;
    margin-left: 60px;
  }

  .welcome3 h3 {
    margin-left: 70px;
    font-size: 20px;
    color: gray;
    margin-top: 10px;
  }

  .welcome3 h1 {
    margin-left: 70px;
    font-size: 35px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .welcome3 p {
    margin-left: 30px;
    margin-top: 25px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 17px;
  }

  .welcome3 button {
    margin-left: 30px;
  }


  .ourroom h1 {
    text-align: center;
    margin-top: 100px;
  }


  .rooms {
    width: 90%;
    height: 400px;
    margin-top: 30px;
    margin-left: 60px;
    display: flex;
  }

  .rooms2 {
    width: 31%;
    height: 100%;
    margin-left: 18px;
    background-color: #fae7bf;
  }

  .rooms2 img {
    width: 100%;
    height: 230px;
  }

  .rooms2 h2 {
    text-align: center;
    font-size: 17px;
    padding-top: 15px;
  }

  .rooms2 p {
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 20px;
    padding-top: 15px;
  }

  .rooms2 h5 {
    text-align: center;
    font-size: 15px;
    padding-top: 20px;
  }

  .rooms2 span {
    color: gray;
    margin-left: 12px;
    font-size: 15px;
  }



  .slide {

    height: 390px;
    width: 87%;
    margin-left: 78px;
    margin-top: 100px;
    display: flex;
  }

  .slide2 {
    width: 24.5%;
    height: 100%;
    background-color: #fae7bf;
    margin-left: 04px;
  }


  .slide2 img {
    width: 100%;
    height: 200px;
  }

  .slide2 p {
    padding-top: 30px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 15px;
    padding-left: 10px;

  }

  .footer {
    width: 100%;
    height: 400px;
    background-color: #232b2b;
    margin-top: 100px;
    display: flex;
  }


  .foot2 {
    width: 25%;
    height: 100%;

  }

  .foot2 h1 {
    color: white;
    font-size: 25px;
    text-align: center;
    padding-top: 30px;
  }


  .foot2 p {
    color: white;
    text-align: center;
    margin-top: 0px;
    font-size: 17px;
  }

  .diaclamer {
    width: 100%;
    height: 170px;
    background-color: #232b2b;
    display: flex;
  }

  .diaclamer h1 {
    padding-left: 0px;
    margin-left: 10px;
    color: white;
    border: 1px solid white;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    padding-top: 10px;
  }

  .diaclamer p {
    color: white;
    margin-left: 300px;
    margin-top: 30px;
  }

}